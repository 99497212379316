<template>
  <y_layout>
    <div slot="left">
      <el-menu router :default-active="$route.path" style="height: 100%">
        <el-menu-item index="/finance/wait" :route="{name:'financeWait'}">提现打款</el-menu-item>
        <el-menu-item index="/finance/log" :route="{name:'financeLog'}">打款记录</el-menu-item>
      </el-menu>
    </div>
    <router-view/>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";

export default {
  name: "index",
  components: {y_layout},
}
</script>

<style scoped>

</style>